<template>
  <ui-form
    @submit="handleSubmit"
    :submitText="$t('actions.save')"
    @cancel="handleCancel"
  >
    <ui-form-field :label="$t('fields.name')" rules="required">
      <el-input type="text" v-model="department.name"></el-input>
    </ui-form-field>

    <ui-form-field :label="$t('fields.description')">
      <el-input type="text" v-model="department.description"></el-input>
    </ui-form-field>

    <ui-form-field :label="$tc('models.building')" rules="required">
      <building-field v-model="department.building"></building-field>
    </ui-form-field>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import BuildingField from '@/components/form/BuildingField';

export default {
  props: {
    department: {
      type: Object,
      required: true
    }
  },

  components: {
    UiForm,
    UiFormField,
    BuildingField
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
